<template>
    <div v-if="params.data">
      <h6 class="mb-0 font-weight-normal text-center">{{ params.data.company? params.data.company: '-' }}</h6>
       <p class="text-muted py-0 mt-n2 text-center">  <i class="flaticon2-location text-muted icon-1x pr-2"></i>{{ params.data.location }}</p>
    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {

        }
    }
</script>

